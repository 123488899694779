<template>
    <Page
        title="Document"
        icon="mdi-file-document"
        :loading-message="loadingMessage"
        :show-loading="isShowLoading"
        :error-message="errorMessage"
        :show-error="isShowError"
        class-extra="mx-auto"
    >
        <v-container>
            <v-row>
                <smart-form
                    v-if="companyCodes.length"
                    v-model="formModel"
                    :schema="formSchema"
                    :data-sets="{
                        companyCodes,
                        documentSources,
                        documentTypes,
                    }"
                    @change="fetchDocumentData"
                    form-code="documentlist"
                    :is-history="isHistory"
                />
            </v-row>
            <v-row>
                <v-col>
                    <smart-table
                        :headers="headers"
                        :items="lines"
                        class="elevation-1"
                        dense
                        show-expand
                        :single-expand="false"
                    >
                        <template v-slot:expanded-item="{ item }">
                            <td colspan="6">
                                <v-simple-table dense>
                                    <template v-slot:default>
                                        <thead>
                                            <tr>
                                                <th class="text-left">
                                                    Item
                                                </th>
                                                <th class="text-left">
                                                    Description
                                                </th>
                                                <th class="text-left">
                                                    Cost Center
                                                </th>
                                                <th class="text-center">
                                                    GST
                                                </th>
                                                <th class="text-right">
                                                    Amount
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="row in item.documentLines"
                                                v-bind:key="row.code"
                                            >
                                                <td>{{ row.item.code }}</td>
                                                <td>{{ row.description }}</td>
                                                <td>{{ row.costCentre.code }}</td>
                                                <td class="text-center">{{ row.taxStatus }}</td>
                                                <td class="text-right">
                                                    <currency-format :value="row.amount" />
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                            </td>
                            <td style="vertical-align: top;">
                                <btn
                                    class="success"
                                    @click="verifyDocument(item.documentId)"
                                    small
                                    >Verify</btn
                                >
                                <btn
                                    class="warning"
                                    @click="verifyDocument(item.documentId, true)"
                                    small
                                    >Fix</btn
                                >
                            </td>
                        </template>
                    </smart-table>
                </v-col>
            </v-row>
        </v-container>
    </Page>
</template>

<script>
import CurrencyFormat from '@/components/format/Currency';

import api from '@/api';
import dateUtil from '@/../common/utils/date';

const formSchema = {
    companyCode: {
        component: 'SelectField',
        label: 'Company',
        dataSets: { items: 'companyCodes' },
        editable: true,
        cols: 4,
    },
    yearMonth: {
        cols: 4,
        component: 'dateField',
        label: 'Search Date',
        month: true,
        today: true,
    },
    documentSource: {
        component: 'RadioField',
        label: 'Source',
        dataSets: { items: 'documentSources' },
        on: { input: {} },
        cols: 2,
    },
    documentType: {
        cols: 4,
        component: 'RadioField',
        label: 'Type',
        dataSets: { items: 'documentTypes' },
        editable: true,
    },
};

const tableHeaders = [
    {
        text: 'Company',
        align: 'center',
        sortable: false,
        value: 'companyCode',
    },
    {
        text: 'Document Date',
        align: 'center',
        sortable: false,
        value: 'documentDate',
    },
    {
        text: 'Vendor/Customer',
        align: 'center',
        sortable: false,
        value: 'vendorCustomer',
    },
    {
        text: 'Document Ref',
        align: 'center',
        sortable: false,
        value: 'documentReference',
    },
    {
        text: 'Status',
        align: 'center',
        sortable: false,
        value: 'documentStatus',
    },
    {
        text: 'Total',
        align: 'right',
        sortable: false,
        value: 'documentTotal',
        customComponent: 'Currency',
    },
    { text: '', value: 'data-table-expand' },
];

export default {
    name: 'DocumentList',
    components: { CurrencyFormat },
    data() {
        return {
            loadingMessage: 'Loading',
            isShowLoading: false,
            errorMessage: 'Error',
            isShowError: false,
            companyCodes: [],
            documentSources: [
                { text: 'AP', value: 'AP' },
                { text: 'AR', value: 'AR' },
                { text: 'GL', value: 'GL' },
            ],
            documentTypes: [],
            lines: [],
            headers: tableHeaders,
            formModel: {
                companyCode: 'SUS',
                yearMonth: '',
                documentSource: 'AR',
                documentType: 'AP',
            },
            formSchema,
            isHistory: false,
        };
    },
    methods: {
        async verifyDocument(documentId, isFixLocalData) {
            console.log('verify', documentId);
            const response = await api.post(this.$store, 'document/verify', {
                documentId,
                isFixLocalData,
            });
            console.log(response.data);
            if (response.data.success) {
                this.$store.dispatch('setInfo', `Document verified successfully`);
            } else {
                this.$store.dispatch(
                    'setError',
                    `${response.data.message} (${response.data.errorCode})`,
                );
            }
        },
        // async unpostDocument(documentId) {
        //
        //     console.log('unpost',documentId);
        //     const path = `document/verify?documentId=${documentId}`;
        //     const response = await api.get(this.$store, path);
        //     console.log(response.data);
        // },
        extractLines() {
            this.lines = this.allDocumentData.map(document => {
                const line = {
                    accountsCompanyId: document.accountsCompanyId,
                    companyCode: document.company.code,
                    documentSource: document.documentSource,
                    documentDate: document.documentDate,
                    documentStatus: document.documentStatus,
                    documentReference: document.documentReference,
                    documentType: document.documentType,
                    dueDate: document.dueDate,
                    documentId: document.id,
                    documentTotal: document.documentTotal,
                    accountsId: document.accountsData ? document.accountsData.Id : null,
                    documentLines: document.lines,
                };
                if (document.lines.length === 0) {
                    console.log('no lines', document.id);
                }
                if (document.documentSource === 'AP') {
                    line.vendorCustomer = document.vendor.code;
                }
                if (document.documentSource === 'AR') {
                    line.vendorCustomer = document.customer.code;
                }
                return line;
            });
        },
        async fetchDocumentData(event) {
            console.log('switch', this.formModel);
            const {
                yearMonth = '',
                companyCode = '',
                documentSource = '',
                documentType = '',
            } = this.formModel;

            let path = `document?yearMonth=${yearMonth}&companyCode=${companyCode}&documentSource=${documentSource}&documentType=${documentType}`;

            const response = await api.get(this.$store, path);
            this.allDocumentData = response.data;
            this.extractLines();
            return {};
        },
        async selectSource(formValue, dataSets) {
            try {
                const useFormValue = { ...formValue };
                const useDataSets = { ...dataSets };
                if (['AP', 'AR'].includes(useFormValue.documentSource)) {
                    useDataSets.documentTypes = ['Invoice', 'Credit'];
                } else if (useFormValue.documentSource === 'GL') {
                    useDataSets.documentTypes = ['Journal'];
                }
                if (!useDataSets.documentTypes.includes(useFormValue.documentType)) {
                    useFormValue.documentType = useDataSets.documentTypes[0]
                        ? useDataSets.documentTypes[0]
                        : '';
                }
                if (!useFormValue.documentSource) {
                    useFormValue.documentType = '';
                }
                return { formValue: useFormValue, dataSets: useDataSets };
            } catch (e) {}
        },
        async fetchCompanyCodes() {
            const path = 'company';
            const response = await api.get(this.$store, path);
            const { companies } = response.data;
            const companyCodes = companies
                .map(company => ({
                    text: `${company.code} - ${company.companyName}`,
                    value: company.code,
                }))
                .sort((a, b) => (a.value > b.value ? 1 : -1));
            this.companyCodes = companyCodes;
        },
        async init() {
            this.isHistory = this.$route.query.isHistory;
            this.formSchema.documentSource.on.input.run = this.selectSource;
            const today = dateUtil.getTodayString();
            this.month = today.substr(0, 7);
            await this.fetchCompanyCodes();
            await this.fetchDocumentData();
        },
    },
    async mounted() {
        await this.init();
    },
};
</script>

<style scoped></style>
